import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

export const dropdownMenuAnimation = trigger('dropdownMenuAnimation', [
  state(
    'visible',
    style({
      opacity: 1,
      transform: 'scale(1)',
    })
  ),
  state(
    'hidden',
    style({
      opacity: 0,
      transform: 'scale(0.95)',
    })
  ),
  transition('visible => hidden', animate('75ms ease-in')),
  transition('hidden => visible', animate('100ms ease-out')),
]);

export const selectPopoverAnimation = trigger('selectPopoverAnimation', [
  state(
    'visible',
    style({
      opacity: 1,
    })
  ),
  state(
    'hidden',
    style({
      opacity: 0,
    })
  ),
  transition('visible => hidden', animate('100ms ease-in')),
]);
